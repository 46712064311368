import { NgModule } from '@angular/core';
import { ImportGsisDeclarationComponent } from './import-gsis-declaration/import-gsis-declaration.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { AddDelButtonsModule } from 'app/main/main-building-blocks/_scams/add-del-buttons/add-del-buttons.module';
import { FormFieldsModule } from 'app/main/main-building-blocks/form-fields/form-fields.module';
import { CodeToDescPipeModule } from 'app/services/pipes/codeToDesc.pipe';
import { DeclarationStatePipeModule } from 'app/services/pipes/declaration-state.pipe';
import { HeaderClassPipeModule } from 'app/services/pipes/headerClass.pipe';
import { ImportGsisDeclarationService } from 'app/main/apps/import-gsis-declaration/import-gsis-declaration.service';



@NgModule({
    declarations: [
        ImportGsisDeclarationComponent
    ],
    imports: [
        FuseSharedModule,
        MaterialModule,
        FormFieldsModule,
        AddDelButtonsModule,
        CodeToDescPipeModule,
        HeaderClassPipeModule,
        DeclarationStatePipeModule,

    ],
    exports: [ImportGsisDeclarationComponent],
    providers: [ImportGsisDeclarationService, { provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }]
}) export class ImportGsisDeclarationModule { }
