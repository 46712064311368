import { ManualPrintDialogComponent } from './../main/apps/printDocuments/manual-print-dialog/manual-print-dialog.component';
import { StateResponse } from 'app/services/declaration-state.service';
import { ErrorDialogComponent, ErrorDialogData } from './../../@fuse/components/error-dialog/error-dialog.component';
import { ePaymentResponseModel } from './../main/apps/e-payment/_models/e-payment-response.model';
import { PrivateControlsModel } from './../model/private-controls.model';
import { EPaymentComponent } from './../main/apps/e-payment/e-payment.component';
import { CustomerInvoiceParsingComponent } from './../main/apps/customer-invoice-parsing/customer-invoice-parsing.component';
import { DefkProsthetaComponent } from './../main/apps/excise/defk/defk-prostheta/defk-prostheta.component';
import { Component, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ArchiveResult, ArchiveSaveDataCommon } from 'app/main/apps/archive/archive.model';
import { ContactsContactFormDialogComponent } from 'app/main/apps/contacts/contact-form/contact-form.component';
import { EAitisiComponent } from 'app/main/apps/e-aitisi/e-aitisi.component';
import { DaoProsthetaComponent } from 'app/main/apps/excise/dao/dao-prostheta/dao-prostheta.component';
import { ArrivalAtExitComponent } from 'app/main/apps/export/arrival-at-exit/arrival-at-exit.component';
import { TariffCalculateComponent } from 'app/main/apps/tariff-calculate/tariff-calculate.component';
import { DeclarationMoreComponent } from 'app/main/main-building-blocks/declaration-more/declaration-more.component';
import { DeclarationSecurityComponent } from 'app/main/main-building-blocks/_transit/declaration-security/declaration-security.component';
import { ChooseDialogComponent } from 'app/main/main-building-blocks/dialogs/choose-dialog/choose-dialog.component';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AttachmentsDialogComponent } from '../main/apps/attachments/attachments-dialog/attachments-dialog.component';
import { CustomsSearchComponent } from '../main/apps/customs/customs-search/customs-search.component';
import { TaricComponent } from '../main/apps/taric/taric.component';
import { TariffInfoDialogComponent } from '../main/apps/taric/tariffs/tariff-info-dialog/tariff-info-dialog.component';
import { SubmitFormService, DeclarationModels } from './submitServices/submitForm.service';
import { TariffCalcArgs } from 'app/main/apps/tariff-calculate/_models';
import { FormErrorsComponent } from './form-errors/form-errors.component';
import { ComponentType } from 'ngx-toastr';
import { DAODeliveryPlaceDialogComponent } from 'app/main/main-building-blocks/_excise/delivery-place/delivery-place-dialog/delivery-place-dialog.component';
import { DaoVehicleDetailsDialogComponent } from 'app/main/apps/excise/dao/vehicle-details-dialog/vehicle-details-dialog.component';
import { Tab } from 'app/main/apps/tab-factory/tab.model';
import { ContactsUpdateFromComponent } from 'app/main/apps/contacts/contacts-update-from/contacts-update-from.component';
import { TransactionsFormComponent } from 'app/main/businesses/transactions/transactions-form/transactions-form.component';
import { Accounts } from 'app/main/businesses/accounts/accounts.service';
import { BusinessModel } from 'app/main/businesses/invoices/_models/business/business.model';
import { GeneralApplicationsComponent } from 'app/main/apps/general-applications/general-applications.component';
import { ImportGsisDeclarationComponent } from 'app/main/apps/import-gsis-declaration/import-gsis-declaration/import-gsis-declaration.component';

export interface RemittanceInput {

    private?: PrivateControlsModel;
    remittance?: ePaymentResponseModel;
}

export interface ConfirmDialogConfig {

    message: string;
    title?: string;
    cancelButton?: string;
    cancelClass?: string;
    confirmButton?: string;
    confirmClass?: string;
    width?: string;
    height?: string;
}
@Injectable({ providedIn: 'root' })
export class OpenDialogsService {

    public errorsDialogRef: MatDialogRef<FormErrorsComponent>;

    constructor(
        public dialog: MatDialog,
        public infoDialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        public submitDialogRef: MatDialogRef<ChooseDialogComponent>,
        public genericDialog: MatDialogRef<any>,
        private submitFormService: SubmitFormService,
        //public calculateTariffRef: MatDialogRef<TariffCalculateResultsComponent>
    ) {
        console.log('SERVICE OpenGenericDialog Started');
    }

    openGenericDialog(component: ComponentType<any>, configArgs: any): Observable<any> {

        const dialogConfig = new MatDialogConfig();

        dialogConfig.panelClass = 'mat-dialog-override';

        for (const [key, value] of Object.entries(configArgs)) {
            console.log(`${key}: ${value}`);
            dialogConfig[key] = value;
        }

        this.genericDialog = this.dialog.open(component, dialogConfig);

        this.genericDialog.keydownEvents().subscribe(event => {
            if (event.key === 'Escape') {
                this.genericDialog.close(this.genericDialog.id);
            }
        });

        this.genericDialog.backdropClick().subscribe(() => {
            this.genericDialog.close(this.genericDialog.id);
        });

        return this.genericDialog
            .afterClosed()
            .pipe(take(1))
    }

    openTaricSearch(currentURL) {
        const configArgs = { width: "100%", height: "100%", data: currentURL }

        return this.openGenericDialog(TaricComponent, configArgs)
    }

    openCustomsSearch() {

        const configArgs = { width: "85%", height: "100%" }

        return this.openGenericDialog(CustomsSearchComponent, configArgs)
    }

    openEfkHelpersDialog(component: ComponentType<any>) {
        const configArgs = { width: "100%", height: "100%", panelClass: ['mat-dialog-override', 'efk-helpers-dialog'] }

        return this.openGenericDialog(component, configArgs)

    }

    openApplicationsDialog(component: ComponentType<any>, dialogTitle: string, cssFilename: string, data?: any) {
        const configArgs = { width: "60%", height: "100%", data: { component, dialogTitle, cssFilename, data } }

        return this.openGenericDialog(GeneralApplicationsComponent, configArgs)

    }

    showTariffInfoDialog(model, measure, typeOfDialog) {

        const configArgs = {
            width: "65%", height: "auto", "minHeight": "100px", data: {
                state: model.state,
                measure: measure,
                show: typeOfDialog
            }
        }

        return this.openGenericDialog(TariffInfoDialogComponent, configArgs)

    }

    openAttachmentDialog(action: string) {
        const configArgs = { width: "70%", height: "95%", data: { action } }

        return this.openGenericDialog(AttachmentsDialogComponent, configArgs)
    }

    sendAttachment() {
        //get Latest State
        return this.openAttachmentDialog('send');
    }

    checkAttachment() {
        return this.openAttachmentDialog('check');
    }

    openArrivalAtExitDialog(declaration?: ArchiveSaveDataCommon, referrerDeclarationId?: string) {

        const configArgs = { width: "100%", height: "100%", data: { declaration, referrerDeclarationId } }

        return this.openGenericDialog(ArrivalAtExitComponent, configArgs)
    }

    openeAitisiDialog(declaration?: ArchiveSaveDataCommon, referrerDeclarationId?: string) {

        const configArgs = { width: "90%", height: "100%", data: { declaration, referrerDeclarationId } }

        return this.openGenericDialog(EAitisiComponent, configArgs)

    }
    openImportGsisDeclarationDialog() {

        const configArgs = { width: "90%", height: "100%", data: {} }

        return this.openGenericDialog(ImportGsisDeclarationComponent, configArgs)

    }

    openDeliveryPlaceDialog(vehicleArrivalConsignee: FormGroup) {

        const configArgs = { width: "45%", height: "70%", autoFocus: false, data: vehicleArrivalConsignee }

        return this.openGenericDialog(DAODeliveryPlaceDialogComponent, configArgs)
    }

    openCalculateTariffDialog(args?: TariffCalcArgs) {

        //const data = 
        const configArgs = {
            id: "calculateTariffDialog", width: "70%", height: "95%", autoFocus: false, data: args || null
        }

        return this.openGenericDialog(TariffCalculateComponent, configArgs)
    }

    ///THIS SHOULD GO TO APPROPRIATE MODULE BECAUSE IT CAUSES NULLINJECCTOR WHEN HERE
    openVehicleDetailsDialog(VehicleDetailsFormGroup: FormGroup, VehicleDetailsIndex: number, formDisabled: boolean, optionalArgs?: any) {

        const configArgs = {
            width: "60%", height: "75%", data: {
                VehicleDetailsFormGroup,
                VehicleDetailsIndex,
                formDisabled
            }
        }

        if (optionalArgs) {
            configArgs.width = optionalArgs.width
            configArgs.height = optionalArgs.height
        }

        return this.openGenericDialog(DaoVehicleDetailsDialogComponent, configArgs)
    }

    openConfirmDialog(config: ConfirmDialogConfig) {
        this.infoDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            restoreFocus: true,
            disableClose: false,
            panelClass: 'mat-dialog-override',
            width: config.width ? config.width : '55%',
            height: config.height ? config.height : '70%'
        });

        this.infoDialogRef.componentInstance.confirmTitle = config.title ? config.title : 'Πληροφορίες';
        this.infoDialogRef.componentInstance.confirmClass = config.confirmClass ? config.confirmClass : 'accent';
        this.infoDialogRef.componentInstance.cancelClass = config.cancelClass ? config.cancelClass : 'primary';
        this.infoDialogRef.componentInstance.confirmButton = config.confirmButton ? config.confirmButton : 'ΟΚ';
        this.infoDialogRef.componentInstance.confirmMessage = config.message;
        if (config.cancelButton) {

            this.infoDialogRef.componentInstance.cancelButton = config.cancelButton;

        }

        this.infoDialogRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape') {
                this.infoDialogRef.close();
            }
        });

        this.infoDialogRef.backdropClick().subscribe(() => {
            this.infoDialogRef.close();
        });

        return this.infoDialogRef.afterClosed().pipe(take(1))

    }



    openDeclarationMore(subdomain: string, declarationForm: FormGroup) {

        const configArgs = {
            width: null,
            height: null,
            restoreFocus: true,
            data: { subdomain, declarationForm }
        }

        switch (subdomain) {

            case 'DAO': {
                configArgs.width = "45%";
                configArgs.height = "55%";
                return this.openGenericDialog(DaoProsthetaComponent, configArgs);
            }
            case 'DEFK': {
                configArgs.width = "50%";
                configArgs.height = "70%";
                return this.openGenericDialog(DefkProsthetaComponent, configArgs);
            }
            default: {
                configArgs.width = "70%";
                configArgs.height = "95%";
                return this.openGenericDialog(DeclarationMoreComponent, configArgs);
            }
        }

    }

    openEPaymentDialog(data: RemittanceInput[]) {
        //const data = 
        const configArgs = {
            id: "ePaymentDialog", width: "70%", height: "95%", data: data || null
        }

        return this.openGenericDialog(EPaymentComponent, configArgs)

    }

    openErrorDialog(errorDialogData: ErrorDialogData) {
        //const data = 
        const configArgs = {
            id: "errorDialog", width: "45%", height: "70%", panelClass: ['mat-dialog-override', 'mat-dialog-override-danger'], data: errorDialogData || null
        }

        return this.openGenericDialog(ErrorDialogComponent, configArgs)

    }
    openDeclarationSecurity(subdomain: string, declarationForm: FormGroup) {

        const configArgs = { width: "70%", height: "95%", data: { subdomain, declarationForm } }

        return this.openGenericDialog(DeclarationSecurityComponent, configArgs)
    }

    createContactDialog(contactType: string, fromDeclaration?: boolean): Observable<any> {

        const configArgs = {
            width: "60%", height: "85%", data: {
                action: 'new',
                class: contactType,
                fromDeclaration
            }
        }

        return this.openGenericDialog(ContactsContactFormDialogComponent, configArgs)
    }

    openTransactionDialog(direction: string, business: BusinessModel, accounts: Accounts): Observable<any> {

        const configArgs = {
            width: "60%", height: "85%", data: {
                action: 'new',
                direction,
                business,
                accounts: accounts.accounts
            }
        }

        return this.openGenericDialog(TransactionsFormComponent, configArgs)
    }

    openImportContactsExternal(contactType: string, external: string) {
        const configArgs = {
            width: "100%", height: "100%", data: {
                contactType,
                external
            }
        }

        return this.openGenericDialog(ContactsUpdateFromComponent, configArgs)

    }
    openSubmitDialog(declarationForm: FormGroup) {
        // if(this.chooseDialogRef.getState)

        //Check with ARR_AT_EXIT because it closes the form dialog on submit 
        // this.dialog.closeAll()
        this.submitDialogRef = this.dialog.open(ChooseDialogComponent, {
            disableClose: false,
            autoFocus: true,
            panelClass: 'mat-dialog-override',
            width: '40%',
            height: '55%'
        });

        this.submitDialogRef.componentInstance.dialogTitle = 'Αποστολή στο ICISNet';
        this.submitDialogRef.componentInstance.contentDescription = 'Να γίνει αποστολή στο ICISNet;';

        this.submitDialogRef.componentInstance.confirmButtons = [
            {
                title: 'Αποστολή',
                class: 'accent',
                action: 'send'
            },
            {
                title: 'Άκυρο',
                class: 'primary',
                action: 'cancel'
            }
        ];

        return this.submitDialogRef.afterClosed()
            .pipe(
                switchMap(result => {

                    if (result?.action === 'send') {

                        this.submitDialogRef = null;

                        console.log("on Submit Action", (declarationForm.getRawValue()))
                        return this.submitFormService.submitDeclaration(declarationForm.getRawValue())

                    } else {

                        this.submitDialogRef.close();

                        this.submitDialogRef = null;

                        return of(null)
                    }
                })
            );
    }
    openSubmitRemittanceDialog() {
        // if(this.chooseDialogRef.getState)

        //Check with ARR_AT_EXIT because it closes the form dialog on submit 
        // this.dialog.closeAll()
        this.submitDialogRef = this.dialog.open(ChooseDialogComponent, {
            disableClose: false,
            autoFocus: true,
            panelClass: 'mat-dialog-override',
            width: '40%',
            height: '55%'
        });

        this.submitDialogRef.componentInstance.dialogTitle = 'Αποστολή στο ICISNet';
        this.submitDialogRef.componentInstance.contentDescription = 'Να γίνει αποστολή στο ICISNet;';

        this.submitDialogRef.componentInstance.confirmButtons = [
            {
                title: 'Αποστολή',
                class: 'accent',
                action: 'send'
            },
            {
                title: 'Άκυρο',
                class: 'primary',
                action: 'cancel'
            }
        ];

        return this.submitDialogRef.afterClosed();
    }

    openInvoiceParsingDialog() {
        const configArgs = { width: "80%", height: "100%" }
        const component = CustomerInvoiceParsingComponent

        return this.openGenericDialog(component, configArgs,)

    }

    checkErrors(declarationForm: FormGroup) {


        //     if (!this.errorsDialogRef || this.errorsDialogRef.getState()===2) {
        if (!this.errorsDialogRef || this.errorsDialogRef.getState() === 2) {
            this.errorsDialogRef = this.dialog.open(FormErrorsComponent, {
                //restoreFocus: true,
                disableClose: false,
                panelClass: 'mat-dialog-override',
                width: '60%',
                height: '75%',
                data: declarationForm
            });
        } else {
            this.errorsDialogRef.close();
        }
    }
    openManualPrintDialog(tabs: Tab[]) {
        const configArgs = { width: "45%", height: "65%", data: tabs }

        return this.openGenericDialog(ManualPrintDialogComponent, configArgs)
    }

    closeDialog(id: string) {
        const index = this.dialog.openDialogs.findIndex(dialog => dialog.id === id)
        //  console.log("closeDialog allOpened index", index)

        this.dialog.getDialogById(id).close()
        this.dialog.openDialogs.splice(index, 1)

        //console.log("closeDialog allOpened", this.dialog.openDialogs)
    }

}
