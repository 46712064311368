import { ToposTirisisEggrafonComponent } from './../../apps/general-applications/topos-tirisis-eggrafon/topos-tirisis-eggrafon/topos-tirisis-eggrafon.component';
import { PATHS } from 'app/navigation/paths';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { RouteStateService } from 'app/services/routeState.service';
import { SaveFormParams, SaveFormService } from 'app/services/saveForm.service';
import { ConfirmDialogConfig, OpenDialogsService } from 'app/services/openDialogs.service';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { BusinessService } from 'app/main/businesses/services/business.service';
import { OfficeService } from 'app/services/office.service';
import { ArchiveTabType } from 'app/main/apps/archive/archive.service';
import { TsipouraComponent } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura.component';
import { BeerComponent } from 'app/main/apps/excise-helper-tables/beer/beer.component';
import { VehiclesComponent } from 'app/main/apps/excise-helper-tables/vehicles/vehicles.component';
import { DistillationLicenceAppComponent } from 'app/main/apps/general-applications/distillation-licence-app/distillation-licence-app/distillation-licence-app.component';
import { SaveAsService } from 'app/services/saveAs.service';
import packageJson from '../../../../../package.json';
import { BUILD_DATE } from 'environments/build-date';
import { ApiService } from 'app/services/api.service';
import saveAs from 'file-saver';


@Component({
    selector: 'ext-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls: ['./shortcuts.component.scss']
})
export class ExtShortcutsComponent implements OnInit, OnDestroy {

    @ViewChild('moreMenu') moreMenu: MatMenu

    shortcutItems: any[];
    navigationItems: any[];
    filteredNavigationItems: any[];
    searching: boolean;
    mobileShortcutsPanelActive: boolean;

    @Input()
    navigation: any;

    @ViewChild('templateDescription') inputRef: ElementRef;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    currentURL: string;
    export: boolean;
    import: boolean;
    emcs: boolean;
    ncts: boolean;
    invoice: boolean;
    contacts: boolean;
    pous: boolean;
    t2l: boolean;
    tir: boolean;
    dao: boolean;
    defk: boolean;

    menus: any;

    description = false;

    stateCode: string;
    testing: boolean;

    subdomain: string;

    officeId: string;
    businessId: string;
    templateId: string;

    isExpanded = true;
    isShowing = false;
    showSubmenu = false;
    progress = 0;
    isDownloading = false;




    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     */
    constructor(
        private _mainBroadcaster: MainBroadcasterService,
        private _saveFormService: SaveFormService,
        private _saveAsService: SaveAsService,
        private _openDialogs: OpenDialogsService,
        private routeState: RouteStateService,
        private officeService: OfficeService,
        private businessService: BusinessService,
        private router: Router,
        private dbQuery: ApiService
    ) {
        // Set the defaults
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;

        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();

        this.routeState.pathParam$.pipe(takeUntil(this._unsubscribeAll)).subscribe((pathParams) => {

            console.log("routeState.pathParam$ on shortcuts", pathParams)

            if (pathParams?.type === 'template') {
                this.templateId = pathParams.id;
            }
            else if (this.router.getCurrentNavigation()?.extras?.state?.template) {

                this.templateId = this.router.getCurrentNavigation().extras.state.templateId
            }
            else {
                this.templateId = null;
            }

            console.log("templateId on shortcuts", this.templateId)

        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        combineLatest([
            this.officeService.currentOffice$,
            this.businessService.currentBusiness$
        ]).pipe(takeUntil(this._unsubscribeAll)).subscribe(([office, business]) => {
            this.officeId = office?.id;
            this.businessId = business?.business_id;
        })


        this.routeState.appUrl$.pipe(delay(0), takeUntil(this._unsubscribeAll)).subscribe((value: string) => {
            this.currentURL = value;
            console.log('SHORTCUTS Current URL ', value);
            this.export = value.includes('/ecs/export') ? true : false;
            this.import = value.includes('/imp/import') ? true : false;
            this.invoice = value.includes('invoice') ? true : false;
            this.contacts = value.includes('contacts') ? true : false;
            this.t2l = value.includes('ncts/t2l') ? true : false;
            this.pous = value.includes('ncts/pous') ? true : false;
            this.tir = value.includes('ncts/tir') ? true : false;
            this.dao = value.includes('emcs/dao') ? true : false;
            this.defk = value.includes('emcs/defk') ? true : false;
        });

    }
    ngAfterViewInit() {
        this.moreMenu.closed.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {

            this.showSubmenu = false;
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Search
     *
     * @param event
     */

    printPage() {
        this._mainBroadcaster.sendPrintPage(false);
    }
    printPhysical() {
        this._mainBroadcaster.sendPrintPage(true);
    }
    save(documentType?: ArchiveTabType, templateDescription?: string) {


        const args = this.getSaveArguments(documentType, templateDescription);

        this._saveFormService.saveForm(args).subscribe();


        if (this.description) {
            this.description = false;
        }
    }

    taric() {
        this._openDialogs.openTaricSearch(this.currentURL).subscribe();
        console.log('taric() on Toolbar');
    }

    saveAsTemplate() {

        if (this.templateId) {

            const args = this.getSaveArguments('template', null);

            args.action = 'update';
            args.type = 'template';
            args.id = this.templateId;
            this._saveAsService.updateItem(args, 'template').subscribe((res) => {

            })

            this.trigger.closeMenu()

        }
        else {
            this.showDescription();
        }
    }

    showDescription() {

        this.description = true;
        setTimeout(() => {
            this.inputRef.nativeElement.focus();
        }, 100);
    }
    customs() {
        this._openDialogs.openCustomsSearch();
    }

    manual() {

        this.router.navigate([PATHS.customsManual(this.officeId)]);
    }

    invoiceSettings() {

        this.router.navigateByUrl(PATHS.invoiceSettings(this.businessId));


    }

    gsisDeclaration() {
        this._openDialogs.openImportGsisDeclarationDialog()
    }

    tsipoura() {

        this._openDialogs.openEfkHelpersDialog(TsipouraComponent)
    }
    beers() {
        this._openDialogs.openEfkHelpersDialog(BeerComponent)
    }
    vehicles() {
        this._openDialogs.openEfkHelpersDialog(VehiclesComponent)
    }

    downloadCBServer(menuTrigger: MatMenuTrigger) {

        this.isDownloading = true;


        this.dbQuery.cbServerDownload().subscribe({
            next: (event) => {
                if (event.type === 'progress') {
                    this.progress = event.value;

                } else if (event.type === 'complete') {
                    const { blob, filename } = event.value;
                    saveAs(blob, filename || 'defaultName.exe');
                    this.isDownloading = false;
                    this.progress = 0;
                    menuTrigger.closeMenu();

                }
            }, error: (error) => {
                console.error('Download error:', error);
                // Handle download error
                this.isDownloading = false;
                this.progress = 0;
                menuTrigger.closeMenu()
            }
        })


    }
    help() {

        const config: ConfirmDialogConfig = {
            message: `   
            
            <h2 style="text-align: center;">Προσοχή, γενναίε εξερευνητή!</h2>
            <p style="text-align: center;">Ενώ το επίσημο τμήμα βοήθειας βρίσκεται σε μυστική αποστολή (ή κάπου για καφέ), εσύ χαράζεις με τόλμη τη δική σου πορεία στην ερημιά της εφαρμογής μας.</p><p>Νιώθεις χαμένος; Ορίστε ένα σετ επιβίωσης:</p>
            <div style="text-align: left !importnat; margin: 0 auto; display: table;">
                <ul style="list-style-position: inside;">
                    <li class="text-left p-3"><strong>Ψυχολογική υποστήριξη</strong>: Αν αισθάνεσαι πελαγωμένος από την απεραντοσύνη της εφαρμογής μας, πάρε βαθιές αναπνοές. Θυμήσου, κάθε μεγάλος εξερευνητής αισθάνεται μερικές φορές ότι βρίσκεται σε αχαρτογράφητα νερά.</li>
                    <li class="text-left p-3"><strong>Ηθική υποστήριξη</strong>: Τα πας περίφημα! Πιστεύουμε σε σένα. Δεν υπάρχει εφαρμογή που να είναι τόσο περίπλοκη, που το λαμπρό μυαλο σου να μην μπορεί να την κατακτήσει.</li>
                    <li class="text-left p-3"><strong>Εικονικά μπράβο</strong>: Για κάθε νέο χαρακτηριστικό που ανακαλύπτεις μόνος σου, φαντάσου μας να σου δίνουμε ένα θριαμβευτικό high five!
                    </li>
                    <li class="text-left p-3"><strong>Τηλεπαθητική βοήθεια</strong>: Αυτή τη στιγμή προσπαθούμε να κατακτήσουμε την τηλεπάθεια για να απαντήσουμε στις ερωτήσεις σου. Δεν έχουμε πολύ τύχη ακόμα, αλλά το παλεύουμε!</li>
                    <li class="text-left p-3"><strong>Πραγματική βοήθεια (κατά κάποιο τρόπο)</strong>: Αν χρειάζεσαι πραγματικά βοήθεια, μη διστάσεις <a href="mailto:akisasim@hotmail.com">να επικοινωνήσεις μαζί μας μέσω email</a>. Μπορεί να μην έχουμε όλες τις απαντήσεις, αλλά σε διαβεβαιώνουμε, οτι είμαστε πολύ καλοί ακροατές!</li>
                </ul>
            </div>
            <p style="text-align: center;">Να θυμάσαι, κάθε κλικ είναι ένα βήμα προς την εκμάθηση της εφαρμογής μας και με κάθε βήμα γίνεσαι μια αυθεντία της εφαρμογής.</p><p>Συνέχισε την εξερεύνηση και απόλαυσε το ταξίδι!</p>
        
        `,
            title: 'Οδηγός Βοήθειας TradeXT - Customs Brokers.',
            confirmClass: 'primary',
            height: "75%"

        }

        this._openDialogs.openConfirmDialog(config).subscribe()
    }

    about() {
        const config: ConfirmDialogConfig = {
            message: `   
            
            <div style="text-align: center;">
            <h2>Σχετικά με το TradeXT - CustomsBrokers</h2>
            <p><strong>Έκδοση:</strong> ${packageJson.version}</p>
            <p><strong>Ημ/νία Έκδοσης:</strong> ${BUILD_DATE}</p>
            <p><strong>Αναπτύχθηκε από:</strong> TradeXT</p>
            <p><strong>Δικτυακός τόπος:</strong> <a href="https://tradext.gr">Επισκεφθείτε τον ιστότοπό μας</a></p>
            <p><strong>Επικοινωνήστε μαζί μας:</strong> <a href="mailto:akisasim@hotmail.com">akisasim@hotmail.com</a></p>
            <p>Για λεπτομερείς πληροφορίες, ενημερώσεις και υποστήριξη, επισκεφθείτε την <a href="https://tradext.gr">Σελίδα Υποστήριξης</a>.</p>
        </div>
        `,
            title: 'Πληροφορίες - TradeXT Customs Brokers.',
            confirmClass: 'primary',
            height: "75%"

        }

        this._openDialogs.openConfirmDialog(config).subscribe()
    }

    documentArchiveApplication() {

        this._openDialogs.openApplicationsDialog(ToposTirisisEggrafonComponent, 'Δήλωση Τόπου Τήρησης Αρχείου Υποστηρικτικών Εγγράφων', 'dilosiTopouTirisisArxeiou')

    }
    distillationLicenceApp() {
        this._openDialogs.openApplicationsDialog(DistillationLicenceAppComponent, 'Αίτηση - Δήλωση Διήμερου Μικρού Αποσταγματοποιού', 'distillersApplication')

    }
    getSaveArguments(documentType?: ArchiveTabType, templateDescription?: string): SaveFormParams {

        let subdomain;


        if (this.export) {
            subdomain = 'EXP_DECL';
        } else if (this.import) {
            subdomain = 'IMP_DECL';
        } else if (this.dao) {
            subdomain = 'DAO'
        }
        else if (this.defk) {
            subdomain = 'DEFK'
        }
        else if (this.t2l) {
            subdomain = 'T2L'
        }
        else if (this.pous) {
            subdomain = 'POUS'
        } else if (this.tir) {
            subdomain = 'TIR'
        }
        const args: SaveFormParams = {
            subdomain,
            systemCall: false,
            type: documentType,
            templateDescription
        };

        if (templateDescription) {
            args.action = 'create';
        }
        return args;
    }


    mouseenter() {
        if (!this.isExpanded) {
            this.isShowing = true;
        }
    }

    mouseleave() {
        if (!this.isExpanded) {
            this.isShowing = false;
        }
    }


}
