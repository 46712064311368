import { OpenDialogsService } from './../../../services/openDialogs.service';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ImportConnectService } from 'app/services/import-connect.service';
import { TariffFinalResponse, TariffDocumentsResponse, TariffState, TariffSupUnitResponse, TariffTotalsType, TariffDescValue, CalcTariffResponse, TariffCalcArgs, TariffDutiesResults } from './_models';
import { TariffCalculateService } from './tariff-calculate.service';
import { environment } from 'environments/environment';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { genericItem } from 'app/model/api-model';

@Component({
    selector: 'app-tariff-calculate',
    templateUrl: './tariff-calculate.component.html',
    styleUrls: ['./tariff-calculate.component.scss']
})
export class TariffCalculateComponent implements OnInit {
    color1 = '';
    color2 = 'primary';
    subheading: string;
    documentsForm: FormGroup;
    documents: TariffDocumentsResponse[];
    taxAddEleForm: FormGroup;
    taxAddElements: TariffDescValue[];
    supUnitsOnCalc: TariffDescValue[];

    supUnits: TariffSupUnitResponse[];

    duties: TariffDutiesResults;
    uvDuties: TariffDutiesResults;
    uvSecurity: TariffDutiesResults;
    private _unsubscribe: Subject<void>;

    processing = false;
    loading: boolean;
    showSupUnits: boolean;
    showDocuments: boolean;
    showResults: boolean;
    disabled = true;

    goodsFormGroup: FormGroup;
    goodsIndex: number;
    privateControls: FormGroup;

    TariffFinalResponse: TariffFinalResponse;

    formDisabled: boolean;

    private _destroy: Subject<void> = new Subject<void>();

    constructor(
        private tariffCalcService: TariffCalculateService,
        @Inject(MAT_DIALOG_DATA) public dialogData: TariffCalcArgs,
        private importConnectService: ImportConnectService,
        private toastrService: ToastrService,
        private cd: ChangeDetectorRef,
        private openDialogService: OpenDialogsService,
        private mainBroadcaster: MainBroadcasterService
    ) {
        console.log('tariffCall Component constuctor', dialogData);

        this.tariffCalcService.onComponentInit();

    }

    ngOnInit(): void {
        if (this.dialogData.tariffResults) {
            this.formDisabled = this.dialogData.formDisabled
            console.log('tariffCall CALC this.dialogData.data', this.dialogData.tariffResults);
            //  this.showResultsTab(this.dialogData)

            this.importConnectService.updateTariffCalculationState({
                state: this.dialogData.state,
                data: this.dialogData.tariffResults
            })

            this.setTransferValues(this.dialogData.tariffResults)
        }

        this.goodsFormGroup = this.dialogData.goodsFormGroup
        this.goodsIndex = this.dialogData.goodsIndex
        this.privateControls = this.dialogData.privateControls

        this.importConnectService.tariffCalculationState$.pipe(takeUntil(this._destroy)).subscribe((value: TariffState) => {

            console.log('tariffCall CALC STATE', value);
            if (value.state === 'loading') {
                this.showLoading()
            } else if (value.state === 'documents') {
                this.showDocumentsTab(value)
            } else if (value.state === 'supUnits') {
                this.showSupUnitsTab(value)
            } else if (value.state === 'results') {
                this.showResultsTab(value)
            }
        });

        this.tariffCalcService.documentsForm$.pipe(takeUntil(this._destroy)).subscribe(form => {
            console.log('tariffCall CALC documentsForm', form);

            this.documentsForm = form;
        });

        this.tariffCalcService.taxAddEleForm$.pipe(takeUntil(this._destroy)).subscribe(form => {
            console.log('tariffCall CALC taxAddEleForm', form);

            this.taxAddEleForm = form;
            this.cd.detectChanges();
        });

        this.mainBroadcaster.resetTariffCalculate$.pipe(takeUntil(this._destroy)).subscribe(() => {

            this.afterSubmit()
            this.openDialogService.closeDialog('calculateTariffDialog')

        })
    }
    ngOnDestroy(): void {
        this.tariffCalcService.onComponentDestroy();
        this._destroy.next();
        this._destroy.complete();
    }

    onCloseConfirm() {
        /*     console.log("OPEN DIALOGS AFTER calcTariffDialog close", this.dialog.openDialogs)
    
            const dialogRef = this.dialog.getDialogById('calculateTariffDialog');
            dialogRef.afterClosed().subscribe(() => {
                console.log("OPEN DIALOGS AFTER calcTariffDialog close", this.dialog.openDialogs)
    
            })
            dialogRef.close() */

        this.openDialogService.closeDialog('calculateTariffDialog')

    }
    changeStyle1(event) {
        this.color1 = event.type === 'mouseenter' ? 'accent' : '';
    }
    changeStyle2(event) {
        if (this.documentsForm.valid) {
            this.color2 = event.type === 'mouseenter' ? 'accent' : 'primary';
        }
    }

    showLoading() {
        this.loading = true;
        this.disabled = true;
        this.showDocuments = false;
        this.showResults = false;
        this.showSupUnits = false;
    }
    showSupUnitsTab(value) {
        this.supUnits = value.data;
        this.tariffCalcService.createSupUnitsForm(value.data);
        this.subheading = 'ΠΡΟΣΘΕΤΑ ΣΤΟΙΧΕΙΑ ΥΠΟΛΟΓΙΣΜΟΥ';
        this.loading = false;
        this.showDocuments = false;
        this.showResults = false;
        this.showSupUnits = true;
    }
    showDocumentsTab(value) {
        this.getFootnotes(value.data).pipe(take(1)).subscribe((footnotes: genericItem[]) => {
            console.log("FOOTNOTES", footnotes)

            this.documents = value.data.map(doc => {
                doc.footnotes = doc.headerLinks
                    .map(link => footnotes.find(f => f.code === link))
                    .filter(Boolean);
                return doc;
            });

            console.log("TARIFF DOCS", this.documents)
            this.tariffCalcService.createDocumentsForm(value.data);
            this.subheading = 'ΕΓΓΡΑΦΑ';
            this.loading = false;
            this.showDocuments = true;
            this.showResults = false;
            this.showSupUnits = false;
        })
    }
    showResultsTab(value) {
        this.TariffFinalResponse = value.data;

        this.subheading = 'ΑΠΟΤΕΛΕΣΜΑΤΑ';
        this.loading = false;
        this.showDocuments = false;
        this.showSupUnits = false;
        this.showResults = true;
    }
    submitDocuments() {
        this.beforeSubmit();
        this.tariffCalcService.submitDocuments().subscribe({
            next: (value: CalcTariffResponse) => {
                console.log('After Documents Results', value);
                // console.log('After Documents data', value.data);

                const state: TariffState = {
                    state: value.tab,
                    data: value.responseData
                }

                if ('duties' in value.responseData) {
                    state['updateFormWithResults'] = true
                    this.setTransferValues(value.responseData)
                }
                this.importConnectService.updateTariffCalculationState(state);

                this.afterSubmit();
            },
            error: (error: unknown) => {
                this.loading = false;
                this.afterSubmit();
            }
        });
    }

    submitSupUnits() {
        this.beforeSubmit();

        this.tariffCalcService.submitSupUnits().subscribe({
            next: (value: CalcTariffResponse) => {
                console.log('Final Results', value);
                console.log('Final Results data', value.responseData);

                if (value.error) {
                    this.showError(value);
                }
                if (value.responseData) {
                    this.importConnectService.updateTariffCalculationState({
                        state: 'results',
                        data: value.responseData,
                        updateFormWithResults: true
                    });

                    if ('duties' in value.responseData) {
                        this.setTransferValues(value.responseData)
                    }
                }
                this.afterSubmit();
            },
            error: (error: unknown) => {
                this.loading = false;
                //this.showError(error)
                this.afterSubmit();
            }
        }
        );
    }
    showError(value) {
        console.log('Error', value.error);
        console.log('Error Length', value.error.length);

        if (value.error.error.length) {
            value.error.error.forEach(error => {
                this.toastrService.error('', error, { disableTimeOut: true });
            });
        } else {
            this.toastrService.error('', value.error.error, { disableTimeOut: true });
        }
    }
    beforeSubmit() {
        this.processing = true;
        this.color2 = 'accent';

        this._unsubscribe = new Subject<void>();
    }
    afterSubmit() {
        if (this._unsubscribe) {
            this._unsubscribe.next();
            this._unsubscribe.complete();
        }
        this.processing = false;
        this.color2 = 'accent';
    }

    onUpdateTariff() {
        this.importConnectService.updateTariffCalculationResults({
            duties: this.duties || this.TariffFinalResponse.duties,
            uvDuties: this.uvDuties || this.TariffFinalResponse.uvDuties,
            uvSecurity: this.uvSecurity || this.TariffFinalResponse.uvSecurity,
            supUnits: this.supUnitsOnCalc || this.TariffFinalResponse.supUnits,
            taxAddElements: this.taxAddElements ?? this.TariffFinalResponse.supUnits
        });

        this.onCloseConfirm();
    }

    setTransferValues(value: TariffFinalResponse) {

        this.duties = value.duties;
        this.uvDuties = value.uvDuties;
        this.uvSecurity = value.uvSecurity
        this.supUnitsOnCalc = value.supUnits;
        this.taxAddElements = this.taxAddEleForm?.get('array').value;
    }



    onPrint() {
        const content = document.getElementById('printable').outerHTML;
        const cssPath = environment.DOMAIN_NAME + '/assets/css/tariff-results-print.css'; // Your logged css path
        const newWindow = window.open('', '_blank');

        if (newWindow && newWindow.document) {
            // Create new link element
            const linkEl = newWindow.document.createElement('link');
            linkEl.setAttribute('rel', 'stylesheet');
            linkEl.setAttribute('type', 'text/css');

            // Set href attribute to the cssPath
            linkEl.setAttribute('href', cssPath);

            // Append the link element to the head of the document
            newWindow.document.head.appendChild(linkEl);

            // Add content to the body of the new window
            newWindow.document.body.innerHTML = content;

            // Listen for the load event on the link element
            linkEl.onload = function () {
                newWindow.print();
                newWindow.close();
            };
        }
    }

    getFootnotes(documents: TariffDocumentsResponse[]): Observable<any> {

        const footnotes: string[] = [];
        documents.forEach(d => {

            footnotes.push(...d.headerLinks)

        })

        //links unique values
        const uniqueLinks = [...new Set(footnotes)];

        console.log("footnotes unique", uniqueLinks);

        return this.tariffCalcService.getFootnoteDescription(uniqueLinks)

    }
}
