import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { eAitisiDeclarationForm } from 'app/main/apps/e-aitisi/_models/declaration/declaration-form.model';
import { eAitisiDeclarationModel } from 'app/main/apps/e-aitisi/_models/declaration/declaration.model';
import { ChooseDialogComponent } from 'app/main/main-building-blocks/dialogs/choose-dialog/choose-dialog.component';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { ApiService } from 'app/services/api.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { OpenDialogsService } from 'app/services/openDialogs.service';
import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { BehaviorSubject, Subject, Observable, take } from 'rxjs';

@Injectable()
export class ImportGsisDeclarationService {

    private gsisDeclarationForm: BehaviorSubject<FormGroup>;
    private _destroy: Subject<void> = new Subject<void>();

    gsisDeclarationForm$: Observable<FormGroup>;

    saveData: FormGroup;
    userId: string;
    declarantId: string;
    traderId: string;
    lrn: string;
    domain: string;
    message: string;
    state: string;
    stateDate: string;
    mrn: string;

    codes: any[];

    metOfPay: string;
    defaultTasks: string[];

    subdomain = 'ICS_DETE';

    declConfig: AutoCompConfigValues = {
        display: { values: ['fullname'] },

        menuOptions: {
            options: ['fullname', 'afm'],
            optionRegular: true
        },
        menuTitles: [null, 'ΑΦΜ: ']

    };

    consConfig: AutoCompConfigValues = {
        display: { values: ['fullname'] },
        menuOptions: { options: ['fullname', 'eori'] }
    };
    customsConfig: AutoCompConfigValues = {
        output: 'ReferenceNumber',
        menuOptions: {
            options: ['CustomsOfficeName', 'ReferenceNumber']
        }
    };

    constructor(
        public matDialog: MatDialog,
        public submitDialogRef: MatDialogRef<ChooseDialogComponent>,
        private fb: FormBuilder,
        private dateTimeService: DateTransformPipe,
        private mainBroadcaster: MainBroadcasterService,
        private dbQuery: ApiService,
        private openDialogsService: OpenDialogsService,
    ) {
        console.log('eAitisi SERVICE INIT');


        const gsisDeclarationForm = this.fb.group({
            xmlData: '',
            trader: null,
            declarant: null,
            international: null,
            mrn: null,
            declarationForm: null,
            subdomain: null,
        });


        this.gsisDeclarationForm = new BehaviorSubject(gsisDeclarationForm);

        console.log('eAitisi SERVICE INIT', this.gsisDeclarationForm.getValue());

        if (!this.gsisDeclarationForm$) {
            this.gsisDeclarationForm$ = this.gsisDeclarationForm.asObservable();

            this.gsisDeclarationForm$.subscribe((form) => console.log("eAITISI Ibser form", form.value));
        }
    }

    ngOnDestroy() {
        console.log('eAitisi SERVICE DESTROYED');

        this._destroy.next();
        this._destroy.complete();
    }

    getDeclarants() {
        return this.mainBroadcaster.declarants$.pipe(take(1));
    }

    getTraders() {
        return this.mainBroadcaster.domecticCustomers$.pipe(take(1));
    }

    getInternational() {
        return this.mainBroadcaster.internationalCustomers$.pipe(take(1));
    }

    deleteFormArray(formArrayName: string, FormArrayIndex: number) {
        const currentDeclaration = this.gsisDeclarationForm.getValue();

        const currentFormArray = currentDeclaration.get(formArrayName) as FormArray;

        currentFormArray.removeAt(FormArrayIndex);
    }

    submit() {
        this.openDialogsService.openSubmitDialog(this.gsisDeclarationForm.getValue()).subscribe();

        console.log('declaration END OF submit() on GeneralComponent component');
    }

}
