import { User, _pelatesItem, international_customersItem } from 'app/model/api-model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { AuthorisationModel } from 'app/main/apps/transit/tir/_models_new/authorisation/authorisation.model';
import { TransitOperationModel } from 'app/main/apps/transit/tir/_models_new/transit-operation/transit-operation.model';
import { CustomsOfficeOfTransitDeclaredModel } from 'app/main/apps/transit/tir/_models_new/customs-office-transit-declared/customs-office-transit-declared.model';
import { CustomsOfficeOfExitForTransitDeclaredModel } from 'app/main/apps/transit/tir/_models_new/customs-office-exit-transit-declared copy/customs-office-exit-transit-declared.model';
import { GuaranteeModel } from 'app/main/apps/transit/tir/_models_new/guarantee/guarantee.model';
import { HolderOfTheTransitProcedureModel } from 'app/main/apps/transit/tir/_models_new/holder-of-transit-procedure/holder-of-transit-procedure.model';
import { RepresentativeModel } from 'app/main/apps/transit/tir/_models_new/representative/representative.model';
import { ConsignmentModel } from 'app/main/apps/transit/tir/_models_new/consignment/consignment.model';
import { ReferenceNumberModel } from 'app/main/apps/transit/tir/_models_new/reference-number/reference-number.model';

export class NewTirDeclarationModel {
    _PRIVATE: PrivateControlsModel;

    messageSender?: string;
    messageRecipient?: string;
    preparationDateAndTime?: string;
    messageIdentification?: string;
    messageType?: string;
    correlationIdentifier?: string;


    TransitOperation?: TransitOperationModel; //////////////
    Authorisation?: AuthorisationModel[]; //////////////
    CustomsOfficeOfDeparture?: ReferenceNumberModel; ////////////
    CustomsOfficeOfDestinationDeclared?: ReferenceNumberModel; ////////////////
    CustomsOfficeOfTransitDeclared?: CustomsOfficeOfTransitDeclaredModel[];
    CustomsOfficeOfExitForTransitDeclared?: CustomsOfficeOfExitForTransitDeclaredModel[]; /////////////////
    HolderOfTheTransitProcedure?: HolderOfTheTransitProcedureModel;
    Representative?: RepresentativeModel; //////////////////
    Guarantee?: GuaranteeModel[];//////////////////////////ON CC013b ONLY===========
    Consignment?: ConsignmentModel; //////////////////////
    constructor(value?) {
        // console.log("___DeclarationModel constructor", value);
        this._PRIVATE = value?._PRIVATE || null;

        this.messageSender = value?.messageSender;
        this.messageRecipient = value?.messageRecipient;
        this.preparationDateAndTime = value?.preparationDateAndTime;
        this.messageIdentification = value?.messageIdentification;
        this.messageType = value?.messageType;
        this.correlationIdentifier = value?.correlationIdentifier

        this.TransitOperation = value?.TransitOperation;
        this.Authorisation = value?.Authorisation;
        this.CustomsOfficeOfDeparture = value?.CustomsOfficeOfDeparture;
        this.CustomsOfficeOfDestinationDeclared = value?.CustomsOfficeOfDestinationDeclared;
        this.CustomsOfficeOfTransitDeclared = value?.CustomsOfficeOfTransitDeclared;
        this.CustomsOfficeOfExitForTransitDeclared = value?.CustomsOfficeOfExitForTransitDeclared;
        this.HolderOfTheTransitProcedure = value?.HolderOfTheTransitProcedure;
        this.Representative = value?.Representative;
        this.Guarantee = value?.Guarantee;
        this.Consignment = value?.Consignment;

    }
}
