<div id="ext-shortcuts" #shortcuts>

    <div class="shortcuts" fxLayout="row">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="0 1 auto">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="w-40 h-40 p-4" fxLayout="row" fxLayoutAlign="center center" *ngIf="invoice">
                    <a mat-icon-button matTooltip="Settings" matTooltipClass="tooltip" (click)="invoiceSettings()">
                        <mat-icon class="secondary-text">settings</mat-icon>
                    </a>
                </div>

                <div class="w-40 h-40 p-4" fxLayout="row" fxLayoutAlign="center center" *ngIf="export || import || t2l">
                    <button mat-icon-button [matMenuTriggerFor]="printMenu" matTooltip="Εκτύπωση"
                        matTooltipClass="ScreenMedia tooltip">
                        <mat-icon class="secondary-text">print</mat-icon>
                    </button>
                </div>

                <div class="w-40 h-40 p-4" fxLayout="row" fxLayoutAlign="center center"
                    *ngIf="export || import || t2l || dao || defk">
                    <button mat-icon-button [matMenuTriggerFor]="saveMenu" matTooltip="Αποθήκευση"
                        matTooltipClass="tooltip">
                        <mat-icon class="secondary-text">save</mat-icon>
                    </button>

                </div>

                <div class="w-40 h-40 p-4" fxLayout="row" fxLayoutAlign="center center" *ngIf="!invoice && !contacts">
                    <button mat-icon-button [matMenuTriggerFor]="moreMenu" matTooltip="Περισσότερα"
                        matTooltipClass="tooltip">
                        <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
                    </button>
                </div>

                <div class="w-40 h-40 p-4" fxLayout="row" fxLayoutAlign="center center">
                    <button mat-icon-button [matMenuTriggerFor]="efkMenu" matTooltip="Βοηθήματα Ε.Φ.Κ."
                        matTooltipClass="tooltip">
                        <mat-icon class="secondary-text">text_fields</mat-icon>
                    </button>
                </div>

                <div class="w-40 h-40 p-4" fxLayout="row" fxLayoutAlign="center center">
                    <button mat-icon-button [matMenuTriggerFor]="helpMenu" #helpMenuTrigger="matMenuTrigger"
                        matTooltip="Βοήθεια & Πληροφορίες" matTooltipClass="tooltip">
                        <mat-icon class="secondary-text">help_outline</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>


    <mat-menu #printMenu="matMenu" class="w-360">
        <mat-nav-list style="max-height: 312px; overflow: auto" fusePerfectScrollbar>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="printPage()">
                    <p matLine fxLayout="row" fxLayoutAlign="start center">
                        Εκτύπωση εγγράφου
                    </p>
                </div>
            </mat-list-item>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="printPhysical()">
                    <p matLine fxLayout="row" fxLayoutAlign="center center">
                        Εκτύπωση σε ΕΔΕ (Φυσικό 'Εγγραφο)
                    </p>
                </div>
            </mat-list-item>

        </mat-nav-list>
    </mat-menu>

    <mat-menu #saveMenu="matMenu" class="w-360">
        <mat-nav-list style="max-height: 312px; overflow: auto" fusePerfectScrollbar>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="save()">
                    <p matLine fxLayout="row" fxLayoutAlign="start center">
                        Αποθήκευση
                    </p>
                </div>
            </mat-list-item>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="save('draft')">
                    <p matLine fxLayout="row" fxLayoutAlign="center center">
                        Αποθήκευση ως Πρόχειρο
                    </p>
                </div>
            </mat-list-item>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start"
                    (click)="$event.stopPropagation(); saveAsTemplate()">
                    <p matLine fxLayout="row" fxLayoutAlign="center center">
                        Αποθήκευση ως Πρότυπο
                    </p>
                </div>
            </mat-list-item>
            <mat-list-item *ngIf="description" (click)="$event.stopPropagation()">
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start">
                    <mat-form-field class="sc-ul s-l s-30 reset">
                        <mat-label>Περιγραφή Προτύπου</mat-label>

                        <input #templateDescription matInput (keyUp.Enter)="
                                save('template', $event.target.value);
                                trigger.closeMenu()
                            " />
                    </mat-form-field>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </mat-menu>

    <mat-menu #moreMenu="matMenu" class="w-360">
        <mat-nav-list style="max-height: 312px; overflow: auto" fusePerfectScrollbar>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="customs()">
                    <p matLine fxLayout="row" fxLayoutAlign="start center">
                        Τελωνεία
                    </p>
                </div>
            </mat-list-item>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="taric()">
                    <p matLine fxLayout="row" fxLayoutAlign="start center">
                        Δασμολόγιο
                    </p>
                </div>
            </mat-list-item>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="manual()">
                    <p matLine fxLayout="row" fxLayoutAlign="start center">
                        Εγχειρίδιο Τελωνειακών Διαδικασιών
                    </p>
                </div>
            </mat-list-item>

            <mat-list-item (click)="$event.stopPropagation(); showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Αιτήσεις/Δηλώσεις</span>
                <mat-icon class="menu-button" [ngClass]="{ rotated: showSubmenu }" *ngIf="isExpanded || isShowing">
                    expand_more</mat-icon>
            </mat-list-item>

            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="gsisDeclaration()">
                    <p matLine fxLayout="row" fxLayoutAlign="start center">
                        Εισαγωγή Διασάφησης απο ICISNet
                    </p>
                </div>
            </mat-list-item>

            <div class="submenu" [ngClass]="{ expanded: showSubmenu }" *ngIf="isShowing || isExpanded">
                <mat-nav-list style="max-height: 400px; overflow: auto" fusePerfectScrollbar>
                    <mat-list-item (click)="documentArchiveApplication()">
                        <div class="w-100-p" fxLayout="row" fxLayoutAlign="center">
                            <p matLine fxLayout="row">Τόπος Τήρησης Αρχείου Εγγράφων</p>
                        </div>
                    </mat-list-item>
                    <mat-list-item (click)="distillationLicenceApp()">
                        <div class="w-100-p" fxLayout="row" fxLayoutAlign="center">
                            <p matLine fxLayout="row">Αίτηση Άδειας Απόσταξης Διήμερου</p>
                        </div>
                    </mat-list-item>
                </mat-nav-list>
            </div>
        </mat-nav-list>
    </mat-menu>


    <mat-menu #efkMenu="matMenu" class="w-360">
        <mat-nav-list style="max-height: 312px; overflow: auto" fusePerfectScrollbar>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="vehicles()">
                    <p matLine fxLayout="row" fxLayoutAlign="start center">
                        Οχήματα
                    </p>
                </div>
            </mat-list-item>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="tsipoura()">
                    <p matLine fxLayout="row" fxLayoutAlign="start center">
                        Διήμεροι
                    </p>
                </div>
            </mat-list-item>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="beers()">
                    <p matLine fxLayout="row" fxLayoutAlign="start center">
                        Μπύρες
                    </p>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </mat-menu>

    <mat-menu #helpMenu="matMenu" class="w-360">
        <mat-nav-list style="max-height: 312px; overflow: auto" fusePerfectScrollbar>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start"
                    (click)="$event.stopImmediatePropagation();downloadCBServer(helpMenuTrigger)">
                    <p matLine fxLayout="row" fxLayoutAlign="start center">
                        Λήψη Εφαρμογής CB Server
                    </p>
                </div>
                <mat-spinner *ngIf="isDownloading" style="padding-right:36px" diameter="36" color="accent"
                    mode="determinate" [value]="progress"></mat-spinner>
                <div *ngIf="isDownloading" class="progress-value"><small>{{progress}}%</small></div>
            </mat-list-item>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="help()">
                    <p matLine fxLayout="row" fxLayoutAlign="start center">
                        Βοήθεια
                    </p>
                </div>
            </mat-list-item>
            <mat-list-item>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="about()">
                    <p matLine fxLayout="row" fxLayoutAlign="start center">
                        Πληροφορίες
                    </p>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </mat-menu>

</div>