import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Declarant } from 'app/core/user/user.model';
import { ArchiveSaveDataCommon } from 'app/main/apps/archive/archive.model';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { ImportGsisDeclarationService } from 'app/main/apps/import-gsis-declaration/import-gsis-declaration.service';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { customsItem } from 'app/model/api-model';
import { ApiService } from 'app/services/api.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { OpenDialogsService } from 'app/services/openDialogs.service';
import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'app-import-gsis-declaration',
    templateUrl: './import-gsis-declaration.component.html',
    styleUrls: ['./import-gsis-declaration.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ImportGsisDeclarationService]

})
export class ImportGsisDeclarationComponent implements OnInit {

    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    @ViewChildren('goodsPanel') _goodsPanel: QueryList<MatExpansionPanel>;
    @ViewChildren('combinedCode') _combinedCode: QueryList<ElementRef>;

    gsisDeclarationForm: FormGroup;
    gsisDeclarationForm$: Observable<FormGroup>;
    private: FormGroup;
    heahea: FormGroup;
    tradec: FormGroup;
    trarep: FormGroup;
    cusoff: FormGroup;
    gooitegds: FormArray;
    refdecl: FormArray;
    tasks: FormArray;

    acceptanceDate: FormControl;
    amendAcceptanceDate: FormControl;

    color1 = '';
    color2 = '';

    eori: string;
    street: string;
    address2: string;
    country: string;

    stateCode: string;

    showButtons: boolean;
    formDisabled: boolean;

    codes: any[] = [];

    traders: Contact[] = [];
    international: Contact[] = [];
    allDeclarants: Declarant[] = [];
    declarants: Declarant[] = [];

    originalDeclarant: Declarant;
    customs: customsItem[] = [];

    declConfig: AutoCompConfigValues;
    consConfig: AutoCompConfigValues;
    customsConfig: AutoCompConfigValues;

    serverOnline$: Observable<boolean>;
    icisOnline$: Observable<boolean>;

    checkboxChecked: boolean;



    parentSubdomain: string;

    private _destroy: Subject<void>;

    get declarant(): FormControl {
        return this.gsisDeclarationForm.get('_PRIVATE').get('declarant') as FormControl;
    }

    get _trader(): FormControl {
        return this.gsisDeclarationForm.get('_PRIVATE').get('trader') as FormControl;
    }



    constructor(
        public importGsisDeclService: ImportGsisDeclarationService,
        public dialogRef: MatDialogRef<ImportGsisDeclarationComponent>,
        private cf: ExtCustomFunctions,
        private dbQuery: ApiService,
        private _mainBroadcaster: MainBroadcasterService,
        private dateTransform: DateTransformPipe,
        private cd: ChangeDetectorRef,
        private openDialogService: OpenDialogsService,
        @Inject(MAT_DIALOG_DATA) public data: {}
    ) { }

    ngOnInit(): void {

        this.getDeclarants().subscribe()
        this.getTraders().subscribe()
        this.getInternational().subscribe()
        this.gsisDeclarationForm$ = this.importGsisDeclService.gsisDeclarationForm$.pipe(
            tap((form) => {
                //this._mainBroadcaster.updateDeclarationForms({ [this.subdomain]: this.eAitisiForm })
                console.log('eAITISI COMP INIT', form.value);

                this.gsisDeclarationForm = form;


            }),
            takeUntil(this._destroy)
        )

        this.declConfig = this.importGsisDeclService.declConfig;
        this.consConfig = this.importGsisDeclService.consConfig;
        this.customsConfig = this.importGsisDeclService.customsConfig;


    }

    ngOnDestroy(): void {
        console.log('eAITISI COMP DESTROYED');

        //restore parent subdomain
        this._destroy.next();
        this._destroy.complete();
    }

    getDeclarants() {
        return this.importGsisDeclService.getDeclarants().pipe(
            tap((data: Declarant[]) => {
                this.allDeclarants = data;
                this.declarants = data;
                //this.declarants = this.cf.filterDeclarants(data, this._trader.value);
            }));
    }

    getTraders() {
        return this.importGsisDeclService.getTraders().pipe(tap((data: Contact[]) => {
            this.traders = data;
        }));
    }
    getInternational() {
        return this.importGsisDeclService.getInternational().pipe(tap((data: Contact[]) => {
            this.international = data;
        }));
    }

    delete(formArrayName, i) {
        this.importGsisDeclService.deleteFormArray(formArrayName, i);
    }

    hideAdd(formArray: FormArray) {
        return this.cf.hideAdd(formArray, 99);
    }
    hideDelete(formArray: FormArray) {
        return this.cf.hideDelete(formArray);
    }

    onCloseConfirm() {

        this.dialogRef.close();

    }
    changeStyle1(event) {
        this.color1 = this.cf.changeStyle1(event);
    }
    changeStyle2(event) {
        if (this.gsisDeclarationForm.valid) {
            this.color2 = this.cf.changeStyle2(this.gsisDeclarationForm, event);
        }
    }
    expandPanel(matExpansionPanel, event) {
        this.cf.expandPanel(matExpansionPanel, event);
    }

    edit() {
        this.cf.editDeclaration(this.gsisDeclarationForm);
    }
    onSubmit() {

        this.importGsisDeclService.submit();
    }

}
