import { StateResponseModel } from './state-response.model';
import { StateResponse } from 'app/services/declaration-state.service';
import declarationData from 'assets/declarationData.json';
import { User, _pelatesItem, genericItem, international_customersItem } from "./api-model";
import { Declarant } from 'app/core/user/user.model';
import { TariffFinalResponse } from 'app/main/apps/tariff-calculate/_models';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { TsipouraModel } from 'app/main/apps/excise-helper-tables/tsipoura/_models/tsipoura.model';
import { BeerModel } from 'app/main/apps/excise-helper-tables/beer/_models/beer.model';
import { VehiclesModel } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles.model';

//export type LastValidState = 'Pre-Submitted' | 'Accepted';
export class PrivateControlsModel implements StateResponse {
    lrn?: string;
    mrn?: string;
    refNumber?: string;
    declarant?: Declarant;
    trader?: User | Contact;
    international?: international_customersItem;
    customs?: string;
    stateCode?: string;
    stateDate?: number;
    submissionDate?: number;
    domain?: string;
    subdomain?: string;
    messageOut?: string;
    messageIn?: string;
    data?: StateResponseModel;
    formBeforeEdit?: any;
    lastValidState?: string;
    exRateManuallyChanged?: boolean;
    certificateIssuanceManuallyChanged?: boolean;
    //======================================Cancel Form Specific=======================
    cancellationDate?: string;
    cancellationReason?: string;
    // _cancellationCustoms?: string;
    //======================================Cancel Form Specific=======================

    taxAmount?: string;

    //==================================FOR CC507 && IB14A   ===========================
    referrerDeclarationId?: string;

    //=======================+GOODSFORMGROUP PRIVATE=======================
    goodsPrivate?: GoodsPrivateModel[];

    //E.F.K.
    twoDayDistillers?: TsipouraModel;
    beer?: BeerModel;
    cars?: VehiclesModel;
    pousNumberOfItems?: number;
    temporaryImportFlag?: boolean;

    //
    constructor(value?: any) {
        let domain: string;
        let subdomain: string;
        let messageType: string;

        if (typeof (value) === 'string') {
            domain = declarationData[value].domain
            subdomain = value
            messageType = declarationData[value].messageType
        }

        this.lrn = value?.lrn || null;
        this.mrn = value?.mrn || null;
        this.refNumber = value?.refNumber || null;
        this.declarant = value?.declarant || null;
        this.trader = value?.trader || null;
        this.international = value?.international || null;
        this.customs = value?.customs || null;
        this.stateCode = value?.stateCode || 'Pre-Submitted';
        this.stateDate = value?.stateDate || null;
        this.submissionDate = value?.submissionDate || null;
        this.domain = value?.domain || domain;
        this.subdomain = value?.subdomain || subdomain;
        this.messageOut = value?.messageOut || messageType;
        this.messageIn = value?.messageIn || null;
        this.data = value?.data || null;
        this.formBeforeEdit = value?.formBeforeEdit || null;
        this.lastValidState = value?.lastValidState || 'Pre-Submitted';
        this.exRateManuallyChanged = value?.exRateManuallyChanged;
        this.certificateIssuanceManuallyChanged = value?.certificateIssuanceManuallyChanged;
        this.cancellationDate = value?.cancellationDate || null;
        this.cancellationReason = value?.cancellationReason || null;
        this.taxAmount = value?.taxAmount || null;
        this.referrerDeclarationId = value?.referrerDeclarationId || null;
        this.goodsPrivate = value?.goodsPrivate || [new GoodsPrivateModel()];
        this.twoDayDistillers = value?.twoDayDistillers || null;
        this.beer = value?.beer || null;
        this.cars = value?.cars || null;
        this.pousNumberOfItems = value?.pousNumberOfItems || 1;
        this.temporaryImportFlag = value?.temporaryImportFlag || false;





    }
}


export class GoodsPrivateModel {

    tariffTaxesSum?: number;
    tariffResults?: TariffFinalResponse;
    obsolescencePercent?: string;
    descriptionEn?: string;
    botanicalName?: string;
    forceDescriptionforCode?: string;
    DOCUMENTS?: PousDocumentsModel[];// used in POUS


    constructor(value?: GoodsPrivateModel) {
        this.tariffTaxesSum = value?.tariffTaxesSum || null;
        this.tariffResults = value?.tariffResults || null;
        this.obsolescencePercent = value?.obsolescencePercent || null;
        this.descriptionEn = value?.descriptionEn || null;
        this.botanicalName = value?.botanicalName || null;
        this.forceDescriptionforCode = value?.forceDescriptionforCode || null;
        this.DOCUMENTS = value?.DOCUMENTS;
    }

}


export class PousDocumentsModel {
    docObject: genericItem;
    ReferenceNumber: string;

    constructor(value?: PousDocumentsModel) {
        this.docObject = value?.docObject || null;
        this.ReferenceNumber = value?.ReferenceNumber || null;
    }
}