<div id="eAitisi" fxLayout="column" style="width: 100%; flex-grow: 1">
    <div mat-dialog-title class="primary">
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 20%">
            <button mat-icon-button  matTooltip="Επιλογές" class="sidebar-toggle mr-12">
                <mat-icon>menu</mat-icon>
            </button>

            <span>Εισαγωγη Διασαφησης από ICISNet</span>
        </div>
          

         
        <div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 20%">
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="flex-start center" mat-dialog-content style="padding: 0 16px;"
        [formGroup]="gsisDeclarationForm$ | async">
     
        <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%">
            <!-- LEFT SIDE -->
            <div fxLayout="column" fxLayoutAlign="center start" style="width: 48%">
                <div fxLayout="column" class="group">
                    <h2>Βασικά Στοιχεία</h2>

                    <!-- FIRST ROW -->
                    <table class="eAitisiHea fourColumns">
                        <tbody>
                            <tr >
                                <td>Αριθμός Αναφοράς</td>
                                <td colspan="3">
                                    <mat-form-field class="s-c sc-ul s-22" appearance="outline" required>
                                        <input matInput formControlName="mrn" maxlength="21" required />
                                    </mat-form-field>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>

                         
                          
                        </tbody>
                    </table>
                </div>
                <div fxLayout="column" fxLayoutAlign="center start" class="group">
                    <h2>Υποβάλλων</h2>
                    <table class="eAitisiHea twoColumns">
                        <tbody>
                            <tr>
                                <td>Υποβάλλων</td>
                                <td>
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-34">
                                        <autocomplete-control formControlName="trader" [options]="traders"
                                            [config]="consConfig" (keyDown.Escape)="$event.stopPropagation();
                                            $event.preventDefault();">
                                        </autocomplete-control>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Εκτελωνιστής</td>
                                <td>
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-34">
                                        <autocomplete-control formControlName="declarant" [options]="declarants"
                                            [config]="declConfig" (keyDown.Escape)="$event.stopPropagation();
                                            $event.preventDefault();">
                                        </autocomplete-control>
                                    </mat-form-field>
                                </td>
                            </tr>
                          
                        </tbody>
                    </table>
                </div>

                  
            </div>

            <!-- RIGHT SIDE -->
            <div fxLayout="column" fxLayoutAlign="center start" style="width: 48%; margin-bottom: 16px;margin-top:32px;">
                                    <mat-form-field appearance="outline" class="s-l sc-ul w-full">
                                      <textarea   matInput name="xmlData" id="" rows="35" style="font-size: 1rem;"></textarea>
                                    </mat-form-field>
        
            </div>

        </div>
    </div>
    <div *ngIf="showButtons">
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
            <button style="width: auto !important" mat-raised-button [ngClass]="color1"
                (mouseenter)="changeStyle1($event)" (mouseleave)="changeStyle1($event)" (click)="onCloseConfirm()">
                Ακυρο
            </button>
            <button style="width: auto !important" mat-raised-button
                [disabled]="!gsisDeclarationForm.valid || gsisDeclarationForm.disabled" [ngClass]="color2"
                (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onSubmit()">
                Υποβολή
            </button>
        </mat-dialog-actions>
    </div>
</div>
