import { Injectable } from '@angular/core';
import { CancelDeclarationComponent } from 'app/main/apps/export/cancel-declaration/cancel-declaration.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeclarationStateService } from 'app/services/declaration-state.service';
import { StateResponse } from "app/services/declaration-state.service";
import { NotificationService } from 'app/services/notifications.service';
import { environment } from 'environments/environment';
import { DeclarationForms, MainBroadcasterService } from 'app/services/broadcaster.service';
import { Observable, combineLatest, tap } from 'rxjs';
import { take } from 'rxjs/operators';
import declarationData from 'assets/declarationData.json';
import { StorageService } from 'app/core/services/storage/storage.service';

@Injectable({ providedIn: 'root' })
export class ModifyDeclarationService {
    lrn: string;
    mrn: string;
    domain: string;

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CancelDeclarationComponent>,
        private notificationService: NotificationService,
        private declarationStateService: DeclarationStateService,
        private mainBroadcaster: MainBroadcasterService,
        private storageService: StorageService

    ) {
        console.log('SERVICE submitForm Started');
    }

    //ΤΡΟΠΟΠΟΙΗΣΗ ΔΙΑΣΑΦΗΣΗΣ============================
    //===================================================
    modifyDeclaration(): Observable<[string, DeclarationForms]> {
        //get CurrentDeclaration Value

        return combineLatest([this.mainBroadcaster.activeSubdomain$, this.mainBroadcaster.declarationForms$])
            .pipe(
                take(1),
                tap(([subdomain, declarationForms]) => {
                    const declarationForm = declarationForms[subdomain];

                    //get Declaration Value to use if edit is aborted
                    //this.mainBroadcaster.updateDeclValueOnEdit(declarationForm.getRawValue())

                    const mrn: string = declarationForm.get('_PRIVATE').get('mrn').value

                    this.storageService.setLocalObject(mrn, declarationForm.getRawValue());

                    console.log('modify subdomain', subdomain);
                    console.log('modify declarationForms', declarationForms);
                    console.log('modify declarationForm', declarationForm);

                    this.getState(subdomain, declarationForm);
                }));



        //get Latest State
    }

    //=====================================================
    getState(subdomain, declarationForm) {

        this.declarationStateService.getLatestState(declarationForm).then((response: StateResponse[]) => {
            console.log('modify getLatestState response:', response);

            let stateCodeValid: boolean = this.stateCodeValid(response[0])

            if (response[0] === undefined || response[0] === null) {
                return;
            }


            /////===============TESTING=====================
            if (environment.testing) {
                if (subdomain === 'DAO' || subdomain === 'DEFK') {
                    this.declarationStateService.setState(declarationForm, 'Registered');

                }
                else {
                    this.declarationStateService.setState(declarationForm, 'Accepted');
                }

                stateCodeValid = true
            }
            //----------------------------------------------

            if (stateCodeValid)
            //if state is Valid
            {
                console.log("CancelEdit ModifyCancelEdit updateDeclValueOnEdit value", declarationForm.getRawValue())


                //declarationForm.get('_PRIVATE').get('formBeforeEdit').setValue(declarationForm.getRawValue());

                this.notificationService.clear();

                //create Modify Form
                this.declarationStateService.setState(declarationForm, 'Edit');

                const modifyMessageType = declarationData[subdomain].modifyMessageType;
                //set message CC513A
                declarationForm.get('MesTypMES20')?.setValue(modifyMessageType);
                declarationForm.get('_PRIVATE').get('messageOut').setValue(modifyMessageType);


                console.log('EDIT declForm After Modify', declarationForm.getRawValue());
            }

            // else show message
            else {
                const mrn: string = declarationForm.get('_PRIVATE').get('mrn').value

                this.storageService.removeLocalObject(mrn);
                this.notificationService.editNotAllowed();
            }
        });
    }

    stateCodeValid(response: StateResponse): boolean {
        const subdomain = response.subdomain;
        const stateCode = response.stateCode;

        switch (subdomain) {
            case 'EXP_DECL': {
                if (
                    stateCode === 'Accepted' ||
                    stateCode === 'Under Release') {
                    return true;
                }

                break;
            }
            case 'IMP_DECL': {
                if (
                    stateCode === 'Accepted' ||
                    stateCode === 'Under Payment' ||
                    stateCode === 'Under Release' ||
                    stateCode === 'Under attach documents'
                ) {
                    return true;
                }

                break;
            }
            case 'TIR':
            case 'T2L': {
                if (
                    stateCode === 'Accepted') {
                    return true;
                }

                break;
            }
            case 'ICS_DETE': {
                if (
                    stateCode === 'Accepted' ||
                    stateCode === 'To be Accepted' ||
                    stateCode === '???ics_paid???'
                ) {
                    return true;
                }

                break;
            }
            case 'DAO':
            case 'DEFK': {
                if (stateCode === 'Registered') {
                    return true;
                }
                break;
            }

        }
        return false;
    }
}
